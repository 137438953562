import { EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { RolesEffects, RolesFacade, rolesFeatureKey, rolesReducer } from '../store';

export function provideRolesDataAccess(): EnvironmentProviders[] {
  return [
    importProvidersFrom(StoreModule.forFeature(rolesFeatureKey, rolesReducer)),
    importProvidersFrom(EffectsModule.forFeature([RolesEffects])),
    makeEnvironmentProviders([RolesFacade]),
  ];
}
