import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { RoleListItem } from '@mp/kernel/roles/domain';

import { RolesActions } from './roles.actions';

export const rolesFeatureKey = 'mpk-core-roles';

type RolesEntityState = EntityState<RoleListItem>;

const rolesEntityAdapter = createEntityAdapter<RoleListItem>({
  selectId: ({ roleId }: RoleListItem) => roleId,
});

export interface RolesState {
  roles: RolesEntityState;
  rolesLoaded: boolean;
}

export const initialState: RolesState = {
  roles: rolesEntityAdapter.getInitialState(),
  rolesLoaded: true,
};

export const rolesReducer = createReducer(
  initialState,
  on(
    RolesActions.fetchRoles,
    (state): RolesState => ({
      ...state,
      roles: rolesEntityAdapter.getInitialState(),
      rolesLoaded: false,
    }),
  ),
  on(
    RolesActions.fetchRolesSuccess,
    (state, { rolesPageResponse }): RolesState => ({
      ...state,
      roles: rolesEntityAdapter.setAll(rolesPageResponse.data, state.roles),
      rolesLoaded: true,
    }),
  ),
  on(
    RolesActions.fetchRolesError,
    (state): RolesState => ({
      ...state,
      rolesLoaded: true,
    }),
  ),
  on(
    RolesActions.deleteRoleSuccess,
    (state, { roleId }): RolesState => ({
      ...state,
      roles: rolesEntityAdapter.removeOne(roleId, state.roles),
    }),
  ),
  on(
    RolesActions.fetchRolesError,
    (state): RolesState => ({
      ...state,
      rolesLoaded: true,
    }),
  ),
  on(
    RolesActions.upsertRoleToList,
    (state, { role }): RolesState => ({
      ...state,
      roles: rolesEntityAdapter.upsertOne(role, state.roles),
    }),
  ),
);

export const { selectAll: selectAllRoles } = rolesEntityAdapter.getSelectors();
