import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PageResponse } from '@core/shared/domain';
import { RoleListItem } from '@mp/kernel/roles/domain';

export const RolesActions = createActionGroup({
  source: 'Kernel - Roles',
  events: {
    fetchRoles: props<{ searchTerm?: string }>(),
    fetchRolesSuccess: props<{ rolesPageResponse: PageResponse<RoleListItem> }>(),
    fetchRolesError: emptyProps(),

    deleteRole: props<{ roleId: string }>(),
    deleteRoleSuccess: props<{ roleId: string }>(),
    deleteRoleError: emptyProps(),

    upsertRoleToList: props<{ role: RoleListItem }>(),
  },
});
