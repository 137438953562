import { Injectable, Signal } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

import { RoleListItem } from '@mp/kernel/roles/domain';

import { RolesActions } from './roles.actions';
import { selectRoles, selectRolesLoaded } from './roles.selectors';

@Injectable()
export class RolesFacade {
  readonly roles: Signal<RoleListItem[]> = this.store$.selectSignal(selectRoles);

  readonly rolesLoaded: Signal<boolean> = this.store$.selectSignal(selectRolesLoaded);

  readonly roleDeleteSuccess$: Observable<{ roleId: string }> = this.actions$.pipe(
    ofType(RolesActions.deleteRoleSuccess),
    map(({ roleId }) => ({ roleId })),
  );

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
  ) {}

  fetchRoles(searchTerm?: string): void {
    this.store$.dispatch(RolesActions.fetchRoles({ searchTerm }));
  }

  deleteRole(roleId: string): void {
    this.store$.dispatch(RolesActions.deleteRole({ roleId }));
  }

  upsertRoleToList(role: RoleListItem): void {
    this.store$.dispatch(RolesActions.upsertRoleToList({ role }));
  }
}
